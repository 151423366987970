import React from "react"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import MetaData from "../components/seo/MetaData"
import { NationalPark, Shelter } from "../models/NationalPark"
import ShelterCard from "../components/cards/ShelterCard"
import ParkSectionText from "../components/nationalpark/ParkSectionText"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  shelters: Shelter[]
  park: NationalPark
}

const SheltersTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`Cabins & Shelters in ${pageContext.park.national_park_name[0].text}`}
        description={`Cabins & Shelters in ${pageContext.park.national_park_name[0].text}. Read more about the shelters and get directions to visit.`}
        thumbnailUrl={pageContext.shelters[0].shelter_image.url}
        imageAlt={`${pageContext.shelters[0].shelter_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className={`container mx-auto px-4`}>
        <h1 className="mt-8 md:mb-0 mb-2">
          Explore cabins and shelters in{" "}
          {pageContext.park.national_park_name[0].text}
        </h1>
        <p className="mt-2 mb-4">
          Read more about the cabins and shelters in the national park. Select a
          shelter and see more details.
        </p>
      </div>
      <WideContainer center={false}>
        <div className="grid grid-cols-1 md:grid-cols-3 py-4">
          {pageContext.shelters.map((element, index) => {
            return (
              <ShelterCard
                key={index.toString()}
                shelter={element}
                parkname={pageContext.park.national_park_name[0].text}
              />
            )
          })}
        </div>
      </WideContainer>
      <ParkSectionText />
    </Layout>
  )
}

export default SheltersTemplate
